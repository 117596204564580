import React from "react";
import ChatUI from "../components/ChatUI/ChatUI";




const HomePage: React.FC = () => {
  return (
    <ChatUI overrideAssistantId={process.env.REACT_APP_HELP_ASSISTANT_ID} />
  );
};

export default HomePage;