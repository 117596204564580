import React from "react";
import { useParams } from "react-router-dom";
import ChatUI from "../components/ChatUI/ChatUI";


type ShareChatContinuePageParams = {
  threadId: string;
}

const ShareChatContinue: React.FC = () => {
  var { threadId } = useParams<ShareChatContinuePageParams>();

  return (
    <ChatUI threadId={threadId} key={threadId} isShared continueSharedChat />
  );
};

export default ShareChatContinue;